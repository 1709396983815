*,
*:after,
*:before {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

* {
    @include scrollbar($secondaryColor, #1e1f2500, 9px, 9px)
}
*:focus {
    outline: none;
    box-shadow: none;
}

body {
    font-size: 16px;
    font-family: $font;
    color: #fff;
    overflow-x: hidden;
    background: #323232;
}
.body-alt {
    background: #424242;
}
::selection {
	background-color: $secondaryColor;
	color: #fff;
}
h1 {
    font-family: $font;
    font-weight: 700;
}

a {
    text-decoration: none;
}
figure {
    margin: 0;
}
img {
    max-width: 100%;
    height: auto;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
}
svg {
    width: 16px;
    height: 16px;
}
p {
    margin: 0;
    font-size: 16px;
    line-height: 150%;
}
ol,
ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}
h1,h2,h3,h4,h5,h6 {
    margin: 0;
    line-height: 150%;
}
.h1 {
    display: block;
    color: $secondaryColor;
    font-size: 46px;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 10px;
    @include mediaTablet {
        font-size: 32px;
    }
    @include mediaPhone {
        font-size: 26px;
    }
}
.h2 {
    display: block;
    color: $secondaryColor;
    font-size: 30px;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 40px;
    @include mediaTablet {
        font-size: 24px;
    }
    @include mediaPhone {
        font-size: 20px;
    }
}
.page {
    margin-top: 50px;
}
.jarallax {
  position: relative;
  background-color: #000000b5;
  z-index: auto !important;
}
.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.flex,
.inline-flex {
    &.jsb {
        justify-content: space-between;
    }

    &.jsa {
        justify-content: space-around;
    }

    &.jfs {
        justify-content: flex-start;
    }

    &.jfe {
        justify-content: flex-end;
    }

    &.jc {
        justify-content: center;
    }

    &.ac {
        align-items: center;
    }

    &.afs {
        align-items: flex-start;
    }

    &.afe {
        align-items: flex-end;
    }

    &.fdc {
        flex-direction: column;
    }
    &.fw {
        flex-wrap: wrap;
    }
}
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
img.lazy-load {
    filter: blur(5px);
    transition: 0.3s filter;
}
img.lazy-load.loaded {
    filter: blur(0px);
}
.container {
    margin: 0 auto;
    max-width: 920px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;

    @include mediaTablet {
        max-width: 728px;
    }

    @include mediaPhone {
        max-width: 100%;
    }
}

.section {
    padding-bottom: 130px;
    padding-top: 130px;

    @include mediaTablet {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    @include mediaPhone {
        padding-bottom: 50px;
        padding-top: 50px;
    }
    &.parallax {
        background: #000000a3;
    }
}