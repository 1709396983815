.advantages {
    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;
        @include mediaTablet {
            grid-template-columns: 1fr 1fr;
            gap: 25px;
        }
        @include mediaPhone {
            grid-template-columns: 1fr;
        }
    }
    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__num {
            font-size: 20px;
            font-weight: 600;
            display: block;
            width: 80px;
            text-align: center;
            padding-bottom: 8px;
            margin-bottom: 25px;
            border-bottom: 1px solid $primaryColor;
        }
        &__title {
            display: block;
            margin-bottom: 40px;
            text-align: center;
            font-size: 22px;
            text-transform: uppercase;
            font-weight: 600;
            color: $primaryColor;
            line-height: 150%;
            @include mediaTablet {
                font-size: 18px;
            }
        }
        &__text {
            text-align: center;
        }
    }
}