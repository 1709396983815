.newsletter {
    background-color: #424242;
    &__title {
        display: block;
        text-align: center;
        color: $secondaryColor;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    &__input {
        width: 100%;
        padding: 8px;
        margin-bottom: 10px;
    }
    &__button {
        width: 100%;
        padding: 8px;
        background-color: $secondaryColor;
        border: none;
        transition: 0.3s background-color;
        cursor: pointer;
        &:hover {
            background-color: #c64900;
        }
    }
}