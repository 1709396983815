@mixin mediaTablet {
  @media screen and (max-width: 992px) {
    @content;
  }
}
@mixin mediaPhone {
  @media screen and (max-width: 576px) {
    @content;
  }
}
@mixin media($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin mediamin($width) {
    @media screen and (min-width: $width) {
        @content;
    }
}

@mixin scrollbar($thumbcolor, $rangecolor, $borderradius, $width) {
  scrollbar-color: $thumbcolor $rangecolor;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    background-color: $rangecolor;
    border-radius: $borderradius;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $thumbcolor;
    border-radius: $borderradius;
  }
}
