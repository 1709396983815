@font-face {
    font-family: "Geometria";
    src: url("/assets/templates/fonts/Geometria-Regular.woff2") format("woff"),
        url("/assets/templates/fonts/Geometria-Regular.ttf") format("truetype"),
        url("/assets/templates/fonts/Geometria-Regular.woff") format("woff");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Geometria";
    src: url("/assets/templates/fonts/Geometria-Medium.woff2") format("woff2"),
        url("/assets/templates/fonts/Geometria-Medium.ttf") format("truetype"),
        url("/assets/templates/fonts/Geometria-Medium.woff") format("woff");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Geometria";
    src: url("/assets/templates/fonts/Geometria-MediumItalic.woff2") format("woff2"),
        url("/assets/templates/fonts/Geometria-MediumItalic.ttf") format("truetype"),
        url("/assets/templates/fonts/Geometria-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Geometria";
    src: url("/assets/templates/fonts/Geometria-Bold.woff2") format("woff2"),
        url("/assets/templates/fonts/Geometria-Bold.ttf") format("truetype"),
        url("/assets/templates/fonts/Geometria-Bold.woff") format("woff");
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "Geometria";
    src: url("/assets/templates/fonts/Geometria-ExtraBold.woff2") format("woff2"),
        url("/assets/templates/fonts/Geometria-ExtraBold.ttf") format("truetype"),
        url("/assets/templates/fonts/Geometria-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: "Geometria";
    src: url("/assets/templates/fonts/Geometria-ExtraBoldItalic.woff2") format("woff2"),
        url("/assets/templates/fonts/Geometria-ExtraBoldItalic.ttf") format("truetype"),
        url("/assets/templates/fonts/Geometria-ExtraBoldItalic.woff") format("woff");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}