.contacts {
    &__title {
        color: #d98e6f;
        font-size: 40px;
        margin-bottom: 20px;
    }
    &__subtitle {
        display: block;
        color: #d98e6f;
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 30px;
    }
    &-form {
        text-align: end;
        margin-bottom: 30px;
        &__wrapper {
            display: flex;
            margin-bottom: 3px;
            @include mediaPhone {
                flex-direction: column;
            }
        }
        &__left {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-right: 3px;
            @include mediaPhone {
                margin-right: 0;
                margin-bottom: 3px;
            }
        }
        &__right {
            width: 100%;
        }
        &__input {
            border: 3px solid #d98e6f;
            background: none;
            padding: 5px;
            color: #fff;
            font-weight: 600;
            &:not(:last-child) {
                margin-bottom: 3px;
            }
            &::placeholder {
                color: #d98e6f;
                font-weight: 600;
            }
            &.textarea {
                width: 100%;
                height: 100%;
                resize: none;
                @include mediaPhone {
                    height: 90px;
                }
            }
            &.error {
                border-color: brown;
            }
        }
        &__button {
            border: none;
            background: #d98e6f;
            padding: 5px;
            color: #323232;
            font-weight: 600;
            cursor: pointer;
            text-align: end;
            transition: 0.3s background;
            &:hover {
                background: #c95a2d;
            }
        }
    }
    &-list {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        &__item {
            display: block;
            color: #d98e6f;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            &:not(:last-child) {
                margin-bottom: 5px;
            }
            & a {
                color: #d98e6f;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
    &-button {
        display: block;
        color: #858585;
        background: #525252;
        padding: 10px;
        font-weight: 800;
        border-radius: 8px;
        text-transform: uppercase;
        box-shadow: 0 29px 10px -7px #030303cc inset;
        transition: .3s background;
        cursor: pointer;
        margin-bottom: 20px;
        width: 200px;
        text-align: center;
        margin-left: auto;
        &:hover {
            background: #323232;
        }
    }
}
#map {
    margin-bottom: 40px;
}
.arrow-button {
    display: block;
    text-align: center;
    width: 150px;
    margin: auto;
    &:hover {
        & .arrow {
            border-color: #675f49;
        }
        & .arrow-text {
            color: #675f49;
        }
    }
    & .arrow {
        display: block;
        border-top: 4px solid #ae9a64;
        border-left: 4px solid #ae9a64;
        width: 22px;
        height: 22px;
        transform: rotate(45deg);
        margin: auto;
        margin-bottom: 10px;
        transition: 0.3s border-color;
    }
    & .arrow-text {
        color: #ae9a64;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        transition: 0.3s color;
    }
}