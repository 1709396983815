.projects {
    padding: 0;
    & .container {
        background: #ebebeb;
        min-height: 1000px;
    }
    &__list {
        padding: 0 80px;
        @include media(780px) {
            padding: 0;
        }
    }
    &-item {
        display: flex;
        padding: 20px 0;
        border-bottom: 1px solid #ae9a64;
        @include media(780px) {
            flex-direction: column;
        }
        &__img {
            width: 320px;
            height: 230px;
            object-fit: cover;
            margin-right: 30px;
            @include mediaTablet {
                width: 220px;
                height: 150px;
            }
            @include media(780px) {
                margin: auto;
                margin-bottom: 20px;
            }
        }
        &__title {
            color: #ae9a64;
            text-align: center;
            display: block;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        &__text {
            margin-bottom: 20px;
            & * {
                color: #000;
                font-size: 12px;
            }
        }
        &__button {
            border: none;
            display: block;
            color: #fff;
            background: #525252;
            padding: 10px;
            font-weight: 800;
            border-radius: 20px;
            box-shadow: 0 29px 10px -7px #030303cc inset;
            transition: .3s background;
            cursor: pointer;
            margin-bottom: 0;
            min-width: 150px;
            width: 150px;
            text-align: center;
            &:hover {
                background: #323232;
            }
        }
    }
}