.gallery {
    &__title {
        margin-top: 40px;
        color: $primaryColor;
    }
    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 0;
        @include mediaTablet {
            grid-template-columns: 1fr 1fr;
        }
    }
    &__item {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #00000000;
            transition: 0.3s background-color;
        }
        &:hover:after {
            background-color: #00000087;
        }
    }
    &__img {
        position: relative;
    }
}