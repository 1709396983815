.magic {
    &-top {
        padding-top: 15px;
        padding-bottom: 15px;
        &__text {
            display: block;
        }
        &__text, &__text a, &__text p {
            color: rgb(217,142,111);
            text-align: center;
            font-weight: 700;
            font-size: 18px;
        }
    }
}
.main {
    padding-top: 60px;
    min-height: 830px;
    @include media(1700px) {
        min-height: 600px;
    }
    @include mediaTablet {
        min-height: 500px;
    }
    &__title {
        margin-bottom: 10px;
        line-height: 150%;
    }
    &__subtitle {
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        display: block;
        text-transform: uppercase;
        line-height: 150%;
        @include mediaTablet {
            font-size: 20px;
        }
        @include mediaPhone {
            font-size: 18px;
        }
    }
    &__wrapper {
        padding-top: 150px;
        display: flex;
        flex-direction: column;
        align-items: end;
        @include mediaTablet {
            padding-top: 100px;
            padding-bottom: 50px;
        }
    }
    & .button {
        display: block;
        color: #fff;
        background: #a69b7e;
        padding: 10px;
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        border-radius: 8px;
        text-transform: uppercase;
        box-shadow: 0px 29px 10px -7px #ffffff57 inset;
        transition: 0.3s background;
        &:hover {
           background: #6a5e3f; 
        }
        &:first-child {
            margin-bottom: 15px;
        }
        &--alt {
            background: $secondaryColor;
            &:hover {
               background: #a7460e; 
            }
        }
    }
}