.content {
    p {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    ul, ol, li {
        list-style: initial;
    }
    li {
        margin-bottom: 15px;
        line-height: 150%;
    }
}