.products {
    padding: 0 !important;
    & .container {
        background: #323232;
        padding-top: 80px;
        padding-bottom: 80px;
        @include mediaTablet {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        @include mediaPhone {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
    &__block {
        height: 80px;
        width: 100%;
        background: #323232;
        @include mediaTablet {
            display: none;
        }
    }
    &__wrapper {
        margin-bottom: 80px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__title {
        color: #ae9a64;
        font-size: 26px;
        text-align: center;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    &__line {
        width: 100px;
        height: 3px;
        background: #ae9a64;
        display: block;
        margin: auto;
        margin-bottom: 20px;
    }
    &__subtitle {
        color: #ae9a64;
        text-align: center;
        display: block;
        margin-bottom: 50px;
        font-size: 18px;
    }
    &__img {
        width: 100%;
        height: auto;
        object-fit: cover;
        max-height: 300px;
        margin-bottom: 40px;
    }
    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 70px;
        margin-bottom: 40px;
        @include mediaTablet {
            grid-template-columns: 1fr 1fr;
            gap: 50px;
        }
        @include mediaPhone {
            grid-template-columns: 1fr;
        }
    }
    &-item {
        text-align: center;
        &__img {
            width: 100%;
            margin-bottom: 20px;
            @include mediaPhone {
                width: 200px;
            }
        }
        &__title {
            color: $primaryColor;
            font-size: 15px;
            display: block;
            margin-bottom: 15px;
        }
        &__line {
            width: 70%;
            height: 2px;
            background: #999999;
            display: block;
            margin: auto auto 15px; 
        }
        &__text {
            color: #ae9a64;
            font-size: 14px;
        }
    }
}