.partners {
    padding-top: 200px;
    padding-bottom: 200px;
    @include mediaTablet {
        padding-bottom: 100px;
        padding-top: 100px;
    }
    &__title {
        color: #d7cdd1;
        margin-bottom: 60px;
    }
    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;
        @include mediaTablet {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @include mediaPhone {
            grid-template-columns: 1fr 1fr;
        }
    }
    &__item {
        margin: auto;
        max-height: 150px;
    }
}