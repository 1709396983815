.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #2f2e2e;
    z-index: 100;
    &-nav {
        @include mediaTablet {
            display: none;
        }
        &__list {
            display: flex;
        }
        & li {
            width: 100%;
            text-align: center;
            &:not(:last-child) a {
                border-right: 1px solid $primaryColor;
            }
            &.active {
                & .header-nav__link {
                    background: $primaryColor;
                    color: #323232;
                }
            }
        }
        &__link {
            display: block;
            padding-bottom: 16px;
            padding-top: 16px;
            background: #323232;
            font-weight: 700;
            font-size: 16px;
            color: $primaryColor;
            text-transform: uppercase;
            transition: 0.3s background, 0.3s color;
            
            &:hover {
                background: $primaryColor;
                color: #323232;
            }
        }
    }
}
.burger-button {
    display: none;
    position: relative;
    border: none;
    width: 32px;
    height: 26px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: space-between;
    @include mediaTablet {
        display: flex;
    }
    &.active {
        & span:nth-child(1) {
            transform: rotate(45deg);
            transform-origin: 0% 0%;
        }
        & span:nth-child(2) {
            transform: scaleY(0);
        }
        & span:nth-child(3) {
            transform: rotate(-45deg);
            transform-origin: 0% 100%;
        }
    }
    & span {
        display: block;
        height: 4px;
        background-color: $primaryColor;
        transition-duration: .1s;
        transition-delay: .1s;
        width: 100%;
        border-radius: 5px;
    }
}
.active.mm-listitem {
    color: #fff;
}